// import axios from "axios";

export function generateRequest(token, data) {
  var config = {
    method: "post",
    url: `${process.env.VUE_APP_DDPW_HOST}/orders/${token}/request`,
    data
  }
  return window.qcsso.request(config);
}

export function payForOrder(token, data) {
  var config = {
    method: "post",
    url: `${process.env.VUE_APP_DDPW_HOST}/orders/${token}/pay`,
    data
  }
  return window.qcsso.request(config);
}

export function payByPoints(merchantId, memberId, data) {
  var config = {
    method: "post",
    url: `${process.env.VUE_APP_COREAPI_HOST}/points/v1/merchants/${merchantId}/members/${memberId}/orders`,
    data
  }
  return window.qcsso.request(config);
}

export function rollbackByRequestId(token, requestId, data) {
  var config = {
    method: "post",
    url: `${process.env.VUE_APP_DDPW_HOST}/orders/${token}/requests/${requestId}/rollback`,
    data
  }
  return window.qcsso.request(config);
}

export function bindMember(partnerCode, merchantId, memberId, data) {
  var config = {
    method: "post",
    url: `${process.env.VUE_APP_COREAPI_HOST}/identities/v1/merchants/${merchantId}/members/${memberId}/pms/${partnerCode}/account-link`,
    data
  }

  return window.qcsso.request(config);
}