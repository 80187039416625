<template>
  <section ref="popNotice" id="popNotice" class="pop notice" @click="onCancelClick">
    <div class="popBody">
      <div>
       
        <div v-if="noticeType" class="icon">
          <font-awesome-icon v-if="noticeType==='success'" icon="fa-regular fa-circle-check" size="5x" />
          <font-awesome-icon v-else-if="noticeType==='error'" icon="fa-regular fa-circle-xmark" size="5x" />
          <div class="orange" v-else-if="noticeType==='info'" >
            <font-awesome-icon icon="fa-solid fa-exclamation" size="3x" />
          </div>
          <img v-else-if="noticeType==='applepay'" src="@/assets/icon/pApple.svg">
        </div>
          
        <span class="title">{{ title }}</span>
        <span v-html="message"></span>
        <!-- <span>{{ message }}</span> -->
      </div>
      <button v-if="showCancelBtn" @click="onCancelClick">
        <span>{{ cancelBtnTitle }}</span>
      </button>
      <button @click="onOkClick">
        <span>{{ okBtnTitle }}</span>
      </button>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
// import { mapState } from 'vuex';

export default {
  name: 'PopupNotice',
  props: {
    title: String,
    message: String,
    noticeType: {
      type: String,
      default: 'info'
    },
    showCancel: {
      type: Boolean,
      default: false,
    },
    cancelBtn: {
      type: String,
      default: function(){
        return this.$i18n.t('取消');
      }
    },
    okBtn: {
      type: String,
      default: function(){
        return this.$i18n.t('確認');
      }
      // default: this.$i18n.t('確認'),
    },
    cancelCallback: {
      type: Function,
    },
    okCallback: {
      type: Function,
    },
  },
  watch: {
    okBtn: {
      handler(newVal) {
        this.okBtnTitle = newVal || this.$i18n.t('Confirm');
      },
    },
    cancelBtn: {
      handler(newVal) {
        this.cancelBtnTitle = newVal || this.$i18n.t('Cancel');
      },
    },
    showCancel: {
      handler(newVal) {
        this.showCancelBtn = newVal;
      },
    },
  },
  data() {
    return {
      okBtnTitle: this.okBtn || this.$i18n.t('Confirm'),
      cancelBtnTitle: this.cancelBtn || this.$i18n.t('Cancel'),
      showCancelBtn: this.showCancel,
    };
  },
  mounted() {
    // this.$el.addEventListener('click', this.onClick)
    // this.popup();
  },
  methods: {
    popClose() {
      let target = this.$refs['popNotice'];
      // target.style.display = 'none';
      
      // this.$emit('close');
      target.children[0].style.animationName = 'popdown'
      setTimeout(() => {
        target.style.display = 'none';
        this.$emit('close');
        target.children[0].style.animationName = 'popup';
      }, 300)
      document.body.style.overflow = 'scroll';
    },
    popup() {
      let target = this.$refs['popNotice'];
      target.style.display = 'flex';
      document.body.style.overflow = 'hidden';
    },
    onCancelClick() {
      if (this.cancelCallback && typeof this.cancelCallback === 'function') {
        this.cancelCallback();
      }
      let target = this.$refs['popNotice'];
      // target.style.display = 'none';
      // this.$emit('cancel');
      target.children[0].style.animationName = 'popdown'
      setTimeout(() => {
        target.style.display = 'none';
        this.$emit('close');
        target.children[0].style.animationName = 'popup';
      }, 300)
      document.body.style.overflow = 'scroll';
    },
    onOkClick() {
      if (this.okCallback && typeof this.okCallback === 'function') {
        console.log('execute ok callback');
        this.okCallback();
      }
      let target = this.$refs['popNotice'];
      // target.style.display = 'none';
      // this.$emit('close');
      target.children[0].style.animationName = 'popdown'
      setTimeout(() => {
        target.style.display = 'none';
        this.$emit('close');
        target.children[0].style.animationName = 'popup';
      }, 300)
      document.body.style.overflow = 'scroll';

    },
    // onClick() {
    //     this.close('close');
    // }
  },
};
</script>
