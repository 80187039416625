<template>
  <section v-if="isAuthRedirect || thirdPartyMember?.uuid">
    <a>
      <span> {{ userName }} </span>
      <!-- <font-awesome-icon icon="fa-solid fa-circle-user" size="xl" /> -->
    </a>
  </section>
  <section v-else-if="!isAuthRedirect">
    <a v-if="ssoAppId && !isLoggedIn" @click="ddauth">
      <span> {{ $t("Login") }} </span>
      <font-awesome-icon icon="fa-solid fa-circle-user" size="xl" />
    </a>
    <a v-if="ssoAppId && isLoggedIn" @click="openLogoutPopup">
      <span> {{ userName }} </span>
      <font-awesome-icon icon="fa-solid fa-circle-user" size="xl" />
    </a>
  </section>

</template>

<script>
// @ is an alias to /src
import { mapState, mapGetters } from "vuex";

export default {
  name: "LoginInButton",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      shopSupportedPoints: (state) => state.supportedPoints,
      ssoAppId: (state) => state.ssoAppId,
      order: (state) => state.orderInfo,
      ssoInitialized: (state) => state.ssoInitialized,
      user: (state) => state.getUser,
    }),
    ...mapGetters({
      userName: 'getUserName',
      thirdPartyMember: 'getThirdPartyMemberShip',
      portalInfo: "portal/getPortalInfo"
    }),
    noPaymentServices() {
      return this.portalInfo?.disableOnlinePayment || this.order?.options?.noPaymentServices;
    },
    isAuthRedirect () {
      return this.$route.query['x-api-authorization'];
    }
  },
  components: {},
  watch: {
    ssoInitialized: {
      handler() {
        console.log(
          "detect if need auto login:",
          this.ssoAppId,
          this.isLoggedIn,
          this.order
        );

        if (this.ssoAppId && !this.isLoggedIn && this.noPaymentServices) {
          this.ddauth();
        }
        // sd時，預設沒有帶mode
        // if (this.ssoAppId) {
        //   this.ddauth();
        // }
        
        // if (this.ssoAppId && !this.isLoggedIn && this.order?.mode) {
        //   this.ddauth();
        // }
      },
    },
  },
  mounted() {},
  methods: {
    openLogoutPopup () {
      this.$store.commit('updateShowLogout', true)
    },
    // logout() {
    //   console.log("[login] start logout");
    //   // const qcsso = window.qcsso;
    //   // let redirectUrl = window.location.protocol + "//" + window.location.host;
    //   // let location = window.location.href;
    //   const token = this.$route.params.token;
    //   const redirectUrl = window.location.origin + '/orders';
    //   this.signout(token, redirectUrl)
    //   //  qcsso
    //   //   .init({
    //   //     appId: this.ssoAppId,
    //   //   })
    //   //   .then(() => {
    //   //     qcsso
    //   //     .logout({ logoutRedirectUri: redirectUrl, state: token })
    //   //     .then(() => {
    //   //       console.log("[login] logout succss");
    //   //       self.$store.commit("updateIsLoggedIn", false);
    //   //     })
    //   //     .catch((e) => {
    //   //       console.error(e);
    //   //     });
    //   //   })
      
    // },
  },
};
</script>
